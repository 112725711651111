// extracted by mini-css-extract-plugin
export var automotiveBenefitsSection = "T_jL";
export var automotiveCasesSection = "T_jQ";
export var automotiveClientQuotesSection = "T_jW";
export var automotiveDevExpertiseSection = "T_jT";
export var automotiveIndustriesSection = "T_jK";
export var automotiveProcessSection = "T_jM";
export var automotiveProjLogoSection = "T_jS";
export var automotiveRequirementsSection = "T_jP";
export var automotiveServicesSection = "T_jJ";
export var automotiveSkilledSection = "T_jV";
export var automotiveTechStackSection = "T_jN";
export var automotiveWhyHireSection = "T_jR";